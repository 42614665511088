@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,800;0,900;1,100;1,200;1,300;1,600;1,800;1,900&display=swap");

$bg-body: #000a1a;
$body-text: #ffffff;
$navbar_height: 100px;
$primary: #cb1e2c;
$secondary: #5ca3df;
// $headings-color: $primary;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    //   xxl: 1400px,
    //   xxxl: 1600px
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    //   xxl: 1320px,
    //   xxxl: 1580px
) !default;


$btn-padding-y: 0.5rem;
$btn-padding-x: 1.5rem;
$btn-font-size: 1.5rem;



//@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

body {
    background-color: $bg-body;
    color: $body-text;
    font-weight: 400;
    font-size: 16px;
    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }
    @include media-breakpoint-up(md) {
        font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 20px;
    }

    
    line-height: 1.5;
    font-family: "Montserrat", sans-serif;
}

.hero {
    background-image: url("./img/hero-bg-mobile.jpg");

    background-repeat: no-repeat;
    background-size: contain;


    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    background-position: center center;

    @include media-breakpoint-up(sm) {
        height: 100vh;
        background-size: 100%;
        background-position: center center;
        background-image: url("./img/hero-bg-tablet.jpg");
    }

    @include media-breakpoint-up(xl) {
        background-image: url("./img/hero-bg.jpg");
    }

    h1 {
        font-size: 32px;

        @include media-breakpoint-up(sm) {

            font-size: 48px;
        }
    }

    .controls {
        margin-bottom: 4rem;
    }
}

h1,
.h1,
h2,
.h2,
.h3,
.h3,
h4,
.h4,
h5,
.h5 {
    font-weight: 700;
    span {
        color: $primary;
    }
}

.section {
    padding-top: 4rem;

    h1 {
        margin-bottom: 2rem;
    }
}

.btn {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    @include media-breakpoint-up(sm) {
        font-size: 1.3rem;
    }
    @include media-breakpoint-up(md) {
        font-size: 1.4rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
    }

    border-radius: 4px;
}

.card-wrapper {
    padding: 2rem;
    border: 1px solid $secondary;
    border-radius: 4px;

    .icon-wrapper {
        font-size: 64px;
        line-height: 1;
        margin-bottom: 1rem;
        color: $secondary;
    }
}

.f-14 {
    font-size: 14px;
}
.btn-outline-secondary {
    color: white;
}


//navbar

.navbar {
    //background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  
    height: 80px; //100px previosly

    @include media-breakpoint-up(sm) {
        height: $navbar_height; //
      }
    // background-color: $shell_bg_color;
    // border-bottom: 1px solid $shell_border_color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0;
  
    .logo {
      position: absolute;
       top: 6px;
      left: 16px;
      height: 50px;
      width: 187px;

      @include media-breakpoint-up(sm) {
        height: 66px;
      width: 247px;
      left: 24px;
      }
      background-image: url("./img/logo-cedo.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all 0.2s;
  
  
      img {
        position: absolute;
      }
  
      .logo-hover {
        transition: all 0.2s ease-out;
  
        &:hover {
          opacity: 0;
        }
      }
    }
  
    .scrolled & {
      background-color: rgba($color: $bg-body, $alpha: 0.8);
      height: 62px; //62 previosly
      transition: all 0.2s;
  
      .logo {
        // img {
        //   width: 44px;
        // }
  
        //background-image: url("../img/logo_mobile.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-color: transparent;
        height: 50px;
        width: 187px;
        top: 6px;
        left: 16px;
        background-size: 100%;
      }
  
      .nav-item {
        a {
          font-size: 1.5rem;
  
          @media (min-width: 961px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 100%;
    position: relative;
    width: 100%;
    // @media (min-width: 1281px) {
    //   max-width: 1580px;
    // }
  }


#header {
    .nav-menu {
      display: flex;
      // grid-template-columns: repeat(4, auto);
      grid-gap: 10px;
      list-style: none;
      text-align: center;
      //width: 60vw;
      justify-content: end;
      margin-right: 0.5rem;
      padding: 0;
      margin-bottom: 0;
      position: absolute;
      right: 1rem;
    }
  
    .nav-item {
      height: 32px;
    }
  
    .nav-links {
      color: #fff;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.15rem 1rem;
      height: 100%;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
     // border-bottom: 2px solid transparent;
     border: 1px solid transparent;
     border-radius: 6px;
    }
  
    .nav-links:hover {
      border: 1px solid #fff;
     // border-bottom: 2px solid #fff;
      transition: all 0.2s ease-out;
      color: #fff;
    }
  
    .nav-links.active {
      border: 1px solid #fff;
    }
  
    .fa-bars {
      color: #fff;
    }
  
    .nav-links-mobile {
      display: none;
    }
  
    .menu-icon {
      display: none;
  
      #checkbox-hamburger-menu {
  
        .text,
        .hamburger {
          display: inline-block;
          font-size: 16px;
          font-weight: 600;
        }
  
      }
    }
  
    @media screen and (max-width: 992px) {
      .NavbarItems {
        position: relative;
      }
  
      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 93vh;
        position: absolute;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: center;
      }
  
      .nav-item {
        height: auto;
      }
  
      .nav-menu.active {
        background-color: rgba($color: $bg-body, $alpha: 0.9);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }
  
      .nav-links {
        text-align: center;
        padding: 1rem 2rem;
        width: 100%;
        display: table;
        font-size: 1.5rem;
      }
  
      .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
      }
  
      .nav-links.active {
        border: 0;
        background-color: rgba($color: #fff, $alpha: 1);
        color: #242424;
      }
  
      .logo {
        img {
          width: 48px;
        }
      }
  
      .menu-icon {
        // display: block;
        // position: absolute;
        // top: 0;
        // right: 0;
        //transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
        font-size: 18px;
        display: flex;
        align-items: center;
        align-self: center;
        position: absolute;
        right: 1.5rem;
        //top: 0;
  
        .text {
          display: flex;
        }
  
        .icon {
          margin-left: 0.5rem;
  
          display: flex;
        }
      }
  
      .fa-times {
        color: #fff;
        font-size: 2rem;
      }
  
      .nav-links-mobile {
        // display: block;
        // text-align: center;
        // margin: 0rem auto;
        // border-radius: 4px;
        // width: 80%;
        // text-decoration: none;
        // font-size: 1.5rem;
        // background-color: transparent;
        // color: #fff;
        // padding: 14px 20px;
        // border: 1px solid #fff;
        // transition: all 0.3s ease-out;
      }
  
      .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
      }
    }
  }
  
  

  // CSS hamburger menu

/* GENERAL STYLES */
.visuallyHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  
  // h1 {
  //   text-align: center;
  // }
  
  
  
  .hamburger {
    margin: 0 auto;
    //margin-top: 30px;
    width: 24px;
    height: 18px;
    position: relative;
    margin-left: 0.75rem;
  }
  
  .hamburger .bar {
    padding: 0;
    width: 24px;
    height: 2px;
    background-color: white;
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute;
  }
  
  .bar1 {
    top: 0;
  }
  
  .bar2,
  .bar3 {
    top: 8px;
  }
  
  .bar3 {
    right: 0;
  }
  
  .bar4 {
    bottom: 0;
  }
  
  /* HAMBURGER 1 */
  .checkbox1:checked+label>.hamburger1>.bar1 {
    transform: rotate(45deg);
    transform-origin: 5%;
    width: 26px;
    background-color: $red;
  }
  
  .checkbox1:checked+label>.hamburger1>.bar2 {
    transform: translateX(-40px);
    background-color: transparent;
  }
  
  .checkbox1:checked+label>.hamburger1>.bar3 {
    transform: translateX(40px);
    background-color: transparent;
  }
  
  .checkbox1:checked+label>.hamburger1>.bar4 {
    transform-origin: 5%;
    transform: rotate(-45deg);
    width: 26px;
    background-color: $red;
  }
  
  /* HAMBURGER 2 */
  
  .checkbox2:checked+label>.hamburger2>.bar1 {
    transform: translateX(40px);
    background-color: transparent;
  }
  
  .checkbox2:checked+label>.hamburger2>.bar2 {
    transform: rotate(45deg);
  }
  
  .checkbox2:checked+label>.hamburger2>.bar3 {
    transform: rotate(-45deg);
  }
  
  .checkbox2:checked+label>.hamburger2>.bar4 {
    transform: translateX(-40px);
    background-color: transparent;
  }
  
  /* HAMBURGER 3 */
  
  .hamburger3 .bar1 {
    transform-origin: 5%;
  }
  
  .hamburger3 .bar4 {
    transform-origin: 5%;
  }
  
  .checkbox3:checked+label>.hamburger3>.bar1 {
    transform: rotate(45deg);
    height: 3px;
    width: 42px;
  }
  
  .checkbox3:checked+label>.hamburger3>.bar3 {
    transform: rotate(45deg);
    height: 3px;
    background-color: transparent;
  }
  
  .checkbox3:checked+label>.hamburger3>.bar2 {
    transform: rotate(-45deg);
    height: 3px;
    background-color: transparent;
  }
  
  .checkbox3:checked+label>.hamburger3>.bar4 {
    transform: rotate(-45deg);
    height: 3px;
    width: 42px;
  }
  
  /* HAMBURGER 4 */
  
  .hamburger2 .bar2,
  .hamburger3 .bar3,
  .hamburger4 .bar4 {
    top: 13.5px;
  }
  
  .hamburger4 .bar5 {
    bottom: 0px;
  }
  
  .hamburger4 .bar {
    transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
  }
  
  .hamburger4 .bar2 {
    width: 1px;
    transform: rotate(90deg);
    left: 13.5px;
  }
  
  .hamburger4 .bar3 {
    width: 1px;
    left: 13.5px;
  
  }
  
  .checkbox4:checked+label>.hamburger4>.bar1 {
    top: 13.5px;
    background-color: transparent;
  }
  
  .checkbox4:checked+label>.hamburger4>.bar2 {
    left: 0px;
    width: 30px;
    transform: rotate(45deg);
  }
  
  .checkbox4:checked+label>.hamburger4>.bar3 {
    left: 0;
    width: 30px;
    transform: rotate(-45deg);
  }
  
  .checkbox4:checked+label>.hamburger4>.bar4 {
    background-color: transparent;
  }
  
  .checkbox4:checked+label>.hamburger4>.bar5 {
    bottom: 13.5px;
    background-color: transparent;
  }
  
  
  .border-1 {
    border: 1px solid #d4d4d4;
  
    @include media-breakpoint-up(lg) {
      border: 1px solid #d4d4d4;
    }
  }
  
  .rounded-2 {
    @media (max-width: 992px) {
      border-radius: 3px !important;
    }
  }



  // loading animation

.loader {
    padding: 12px;
   // background: white;
    border-radius: 50%;
   // margin: 10px;
    height: 110px;
    width: 110px;
  
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    transform: scale(1);
    animation: pulse 1s infinite;
  
    position: absolute;
    top: calc(50% - 55px);
    left: calc(50% - 55px);
  
    display: flex;
    align-items: center;
  
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.90);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
  
    70% {
      transform: scale(1.1);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
  
    100% {
      transform: scale(0.90);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }